import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function sendWhatsAppNotification(to, body) {
  try {
    const response = await axios.post(`${API_URL}/api/test-whatsapp`, { to, body });
    console.log('WhatsApp notification sent successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending WhatsApp notification:', error.response ? error.response.data : error.message);
    throw error;
  }
}