import React, { useState, useEffect, useCallback, useRef } from 'react';
import { firestore } from '../firebase';
import '../App.css';

function Users() {
  const usersRef = firestore.collection('users');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    password: ''
  });

  const formRef = useRef(null);

  // Fetch users only once
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const snapshot = await usersRef.get();
        const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(usersData);
        setFilteredUsers(usersData); // Initialize filtered users
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [usersRef]);

  // Handle form changes in a single function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Filter users in memory without additional Firestore reads
  useEffect(() => {
    if (searchName.trim()) {
      const filtered = users.filter(user => user.name.toLowerCase().includes(searchName.toLowerCase()));
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users); // Reset to all users when search is cleared
    }
  }, [searchName, users]);

  const resetForm = () => {
    setSelectedUser(null);
    setFormData({
      name: '',
      email: '',
      phone: '',
      role: '',
      password: ''
    });
  };

  // Memoize the function to avoid recreating it on every render
  const handleAddUser = useCallback(async (e) => {
    e.preventDefault();

    const { name, email, phone, role, password } = formData;

    // Basic validation
    if (!name || !email || !phone || !role || !password) {
      alert("يرجى ملء جميع الحقول المطلوبة");
      return;
    }

    if (phone.length < 10) {
      alert("يجب أن يكون رقم الجوال مكون من 10 أرقام على الأقل");
      return;
    }

    try {
      if (selectedUser) {
        // Update existing user
        await usersRef.doc(selectedUser).update(formData);
        const updatedUsers = users.map(user => (user.id === selectedUser ? { ...user, ...formData } : user));
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
        alert("تم تعديل السجل بنجاح");
      } else {
        // Check if email is already in use
        const emailExists = users.some(user => user.email === email);
        if (emailExists) {
          alert("البريد الإلكتروني مسجل من قبل");
          return;
        }

        // Add new user
        await usersRef.add(formData);
        setUsers([...users, { ...formData }]);
        setFilteredUsers([...users, { ...formData }]);
        alert("تم الحفظ بنجاح");
      }
      resetForm();
    } catch (error) {
      console.error("Error adding/updating user:", error);
      alert("حدث خطأ أثناء عملية الحفظ");
    }
  }, [formData, selectedUser, users, usersRef]);

  const handleDelete = async (id) => {
    if (window.confirm("هل أنت متأكد من حذف هذا المستخدم؟")) {
      try {
        await usersRef.doc(id).delete();
        const updatedUsers = users.filter(user => user.id !== id);
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
        alert("تم حذف السجل بنجاح");
      } catch (error) {
        console.error("Error deleting user:", error);
        alert("حدث خطأ أثناء حذف المستخدم");
      }
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user.id);
    setFormData({
      name: user.name,
      email: user.email,
      phone: user.phone,
      role: user.role,
      password: user.password
    });
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="container">
      <h1>المستخدمين</h1>
      <form ref={formRef} onSubmit={handleAddUser} autoComplete="on" className='form1'>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="الاسم الكامل"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="البريد الإلكتروني (example@example.com)"
          required
        />
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          placeholder="كلمة المرور"
          required
        />
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          placeholder="رقم الجوال"
          required
        />
        <p>يجب الا يقل رقم الجوال عن 10 أرقام</p>
        <select name="role" value={formData.role} onChange={handleInputChange} required className="form-input1">
          <option value="">اختر الدور</option>
          <option value="admin">Admin</option>
          <option value="manager">مدير</option>
          <option value="supervisor">مشرف</option>
          <option value="user">مستخدم</option>
        </select>
        <button type="submit" className="add-button">
          {selectedUser ? 'تحديث المستخدم' : 'إضافة المستخدم'}
        </button>
      </form>

      <div className="search-container">
        <input
          type="text"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          placeholder="بحث باسم المستخدم"
          className="form-input"
        />
        <button onClick={() => setSearchName('')}>إعادة تعيين</button>
      </div>

      <div className="records-count">
        <h2>إجمالي عدد السجلات: {filteredUsers.length}</h2>
      </div>

      <table>
        <thead>
          <tr>
            <th>الاسم الكامل</th>
            <th>البريد الإلكتروني</th>
            <th>رقم الجوال</th>
            <th>الدور</th>
            <th>تعديل</th>
            <th>حذف</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.phone}</td>
              <td>{user.role}</td>
              <td>
                <button onClick={() => handleEdit(user)} className="small-edit-button">تعديل</button>
              </td>
              <td>
                <button onClick={() => handleDelete(user.id)} className="small-delete-button">حذف</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Users;
