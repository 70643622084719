import React, { useState, useEffect, useRef } from 'react';
import { firestore } from '../firebase';
import '../App.css'; // Import styling from App.css
import { v4 as uuidv4 } from 'uuid';

function Projects() {
  const projectsRef = firestore.collection('projects');
  const [projects, setProjects] = useState([]);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [searchType, setSearchType] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);

  const [projectName, setProjectName] = useState('');
  const [details, setDetails] = useState('');
  const [priority, setPriority] = useState('');
  const [notes, setNotes] = useState('');
  const [projectType, setProjectType] = useState('');
  const [projectLink, setProjectLink] = useState('');

  const formRef = useRef(null);

  // Use Firestore listener to minimize the number of reads
  useEffect(() => {
    const unsubscribe = projectsRef.onSnapshot(snapshot => {
      const projectsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(projectsData);
      setSortedProjects(projectsData); // Initialize sorted projects
    });

    // Cleanup the listener when component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  // Debounce search input to minimize frequent filtering
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleSearch();
    }, 300); // 300ms debounce for search

    return () => clearTimeout(timeoutId); // Clear timeout if the user keeps typing
  }, [searchName, searchType]);

  const handleSearch = () => {
    let filtered = projects;

    if (searchName) {
      filtered = filtered.filter(project =>
        project.projectName.toLowerCase().includes(searchName.toLowerCase())
      );
    }

    if (searchType) {
      filtered = filtered.filter(project =>
        project.projectType.toLowerCase().includes(searchType.toLowerCase())
      );
    }

    setSortedProjects(filtered);
  };

  const addOrUpdateProject = async (e) => {
    e.preventDefault();

    if (!projectName || !projectType) {
      alert("يرجى ملء جميع الحقول المطلوبة");
      return;
    }

    try {
      if (selectedProject) {
        await projectsRef.doc(selectedProject.id).update({
          projectName,
          details,
          priority,
          notes,
          projectType,
          projectLink
        });
      } else {
        const newProject = {
          id: uuidv4(),
          projectName,
          details,
          priority,
          notes,
          projectType,
          projectLink
        };
        await projectsRef.doc(newProject.id).set(newProject);
      }

      alert("تم الحفظ بنجاح");
      resetForm();
    } catch (error) {
      console.error("Error adding/updating document: ", error);
      alert("حدث خطأ أثناء عملية الحفظ");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("هل أنت متأكد من حذف هذا المشروع؟")) {
      try {
        await projectsRef.doc(id).delete();
        alert("تم حذف السجل بنجاح");
      } catch (error) {
        console.error("Error deleting document: ", error);
        alert("حدث خطأ أثناء حذف السجل");
      }
    }
  };

  const handleEdit = (project) => {
    setSelectedProject(project);
    setProjectName(project.projectName);
    setDetails(project.details);
    setPriority(project.priority);
    setNotes(project.notes);
    setProjectType(project.projectType);
    setProjectLink(project.projectLink);
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const resetForm = () => {
    setSelectedProject(null);
    setProjectName('');
    setDetails('');
    setPriority('');
    setNotes('');
    setProjectType('');
    setProjectLink('');
  };

  const resetFilters = () => {
    setSearchName('');
    setSearchType('');
    setSortedProjects(projects);
  };

  return (
    <div className="container">
      <h1>المشاريع</h1>
      <form onSubmit={addOrUpdateProject} ref={formRef}>
        <input 
          type="text" 
          value={projectName} 
          onChange={(e) => setProjectName(e.target.value)} 
          placeholder="اسم المشروع" 
          required 
        />
        <input 
          type="text" 
          value={details} 
          onChange={(e) => setDetails(e.target.value)} 
          placeholder="التفاصيل" 
        />
        <select value={priority} onChange={(e) => setPriority(e.target.value)} required>
          <option value="">اختر الأولوية</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
        <select value={projectType} onChange={(e) => setProjectType(e.target.value)} required>
          <option value="">اختر نوع المشروع</option>
          <option value="برمجي">برمجي</option>
          <option value="مساعد اداري">مساعد اداري</option>
          <option value="ذكاء اصطناعي">ذكاء اصطناعي</option>
          <option value="تصاميم">تصاميم</option>
          <option value="اشتراكات">اشتراكات</option>
        </select>
        <textarea 
          value={notes} 
          onChange={(e) => setNotes(e.target.value)} 
          placeholder="ملاحظات" 
        />
        <input 
          className="url-input"
          type="url" 
          value={projectLink} 
          onChange={(e) => setProjectLink(e.target.value)} 
          placeholder="رابط ملفات المشروع (مثال: https://drive.google.com/)" 
          required 
        />
        <small className='url-description'>مثال للرابط: https://drive.google.com/drive/folders/xxxxxxx</small>
        <button type="submit">{selectedProject ? 'تحديث المشروع' : 'إضافة المشروع'}</button>
      </form>
      <div className="search-container">
        <input 
          className="search-input"
          type="text" 
          value={searchName} 
          onChange={(e) => setSearchName(e.target.value)} 
          placeholder="بحث باسم المشروع" 
        />
        <select 
          className="search-input" 
          value={searchType} 
          onChange={(e) => setSearchType(e.target.value)}>
          <option value="">بحث بنوع المشروع</option>
          <option value="برمجي">برمجي</option>
          <option value="مساعد اداري">مساعد اداري</option>
          <option value="ذكاء اصطناعي">ذكاء اصطناعي</option>
          <option value="تصاميم">تصاميم</option>
        </select>
        <button onClick={resetFilters}>إعادة تعيين</button>
      </div>
      <div className="records-count">
        <h2>إجمالي عدد السجلات: {sortedProjects.length}</h2>
      </div>
      <table>
        <thead>
          <tr>
            <th>اسم المشروع</th>
            <th>التفاصيل</th>
            <th>الأولوية</th>
            <th>الملاحظات</th>
            <th>نوع المشروع</th>
            <th>رابط ملفات المشروع</th>
            <th>تعديل</th>
            <th>حذف</th>
          </tr>
        </thead>
        <tbody>
          {sortedProjects.map(project => (
            <tr key={project.id}>
              <td>{project.projectName}</td>
              <td>{project.details}</td>
              <td>{project.priority}</td>
              <td>{project.notes}</td>
              <td>{project.projectType}</td>
              <td><a href={project.projectLink} target="_blank" rel="noopener noreferrer">رابط الملفات</a></td>
              <td><button onClick={() => handleEdit(project)} className="small-edit-button">تعديل</button></td>
              <td><button onClick={() => handleDelete(project.id)} className="small-delete-button">حذف</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Projects;
