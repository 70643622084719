import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter, NavLink } from 'react-router-dom';
import './App.css';
import { auth, firestore } from './firebase';

import Customers from './pages/Customers';
import Projects from './pages/Projects';
import Tasks from './pages/Tasks';
import Services from './pages/Services';
import Users from './pages/Users';
import Settings from './pages/Settings';
import Log from './pages/Log';
import Login from './pages/Login';
const SESSION_TIMEOUT = 2 * 60 * 60 * 1000; // ساعتين
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedUsername = localStorage.getItem('username');
    if (savedEmail && savedUsername) {
      setIsAuthenticated(true);
      setUsername(savedUsername);
    }
  }, []);

  const handleLoginSuccess = async (username) => {
    setIsAuthenticated(true);
    setUsername(username);
    await logActivity('login', 'User', username); // تسجيل الدخول في سجل الأنشطة
  };

  const handleLogout = async () => {
    await logActivity('logout', 'User', username); // تسجيل الخروج في سجل الأنشطة
    setIsAuthenticated(false);
    setUsername('');
    localStorage.removeItem('email');
    localStorage.removeItem('username');
  };

  const logActivity = async (action, entityType, username, entityData) => {
    try {
      const userDoc = await firestore.collection('users').doc(auth.currentUser.uid).get();
      const userName = userDoc.exists ? userDoc.data().name : 'Unknown User';

      await firestore.collection('log').add({
        action,
        entityType,
        username: userName,
        entityData,
        timestamp: new Date(),
      });
    } catch (error) {
      console.error('Error logging activity:', error);
    }
  };

  return (
    <HashRouter>
      <div className={`App ${isAuthenticated ? '' : 'login-mode'}`}>
        <header className="App-header">
          {isAuthenticated && (
            <div className="header-content">
              <p className="welcome-message">مرحباً {username}</p>
              <button className="logout-button" onClick={handleLogout}>تسجيل الخروج</button>
            </div>
          )}
          <h1>QV-CRM - بــرنــامــج العــمــلاء</h1>
          {isAuthenticated && (
            <nav className="navbar">
              <ul>
                <li><NavLink to="/customers" className={({ isActive }) => (isActive ? 'active' : '')}>العملاء</NavLink></li>
                <li><NavLink to="/projects" className={({ isActive }) => (isActive ? 'active' : '')}>المشاريع</NavLink></li>
                <li><NavLink to="/tasks" className={({ isActive }) => (isActive ? 'active' : '')}>المهام</NavLink></li>
                <li><NavLink to="/services" className={({ isActive }) => (isActive ? 'active' : '')}>الخدمات</NavLink></li>
                <li><NavLink to="/users" className={({ isActive }) => (isActive ? 'active' : '')}>المستخدمين</NavLink></li>
                <li><NavLink to="/settings" className={({ isActive }) => (isActive ? 'active' : '')}>الإعدادات</NavLink></li>
                <li><NavLink to="/log" className={({ isActive }) => (isActive ? 'active' : '')}>السجل</NavLink></li>
              </ul>
            </nav>
          )}
        </header>
        {!isAuthenticated ? (
          <Login onLoginSuccess={handleLoginSuccess} />
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to="/customers" />} />
            <Route path="/customers" element={<Customers username={username} />} />
            <Route path="/projects" element={<Projects username={username} />} />
            <Route path="/tasks" element={<Tasks username={username} />} />
            <Route path="/services" element={<Services username={username} />} />
            <Route path="/users" element={<Users username={username} />} />
            <Route path="/settings" element={<Settings username={username} />} />
            <Route path="/log" element={<Log />} />
            <Route path="/login" element={<Navigate to="/customers" />} />
          </Routes>
        )}
      </div>
    </HashRouter>
  );
}

export default App;
