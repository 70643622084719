import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import '../App.css';

const Log = () => {
  const [logs, setLogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchLogs = async () => {
      const logRef = firestore.collection('log');
      const snapshot = await logRef.orderBy('timestamp', 'desc').get();
      const logData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLogs(logData);
    };

    fetchLogs();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredLogs = logs.filter(log =>
    (log.username && log.username.toLowerCase().includes(searchQuery.toLowerCase())) ||
    log.action.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <h1>سجل العمليات</h1>
      <input
        type="text"
        placeholder="بحث باسم المستخدم أو العملية"
        value={searchQuery}
        onChange={handleSearch}
      />
      <table>
        <thead>
          <tr>
            <th>المستخدم</th>
            <th>العملية</th>
            <th>اسم الصفحة</th>
            <th>السجل</th>
            <th>التاريخ</th>
          </tr>
        </thead>
        <tbody>
          {filteredLogs.map(log => (
            <tr key={log.id}>
              <td>{log.username || 'Unknown User'}</td>
              <td>{log.action}</td>
              <td>{log.entityType}</td>
              <td>{log.entityData ? log.entityData.name : 'N/A'}</td>
              <td>{new Date(log.timestamp.toDate()).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Log;
