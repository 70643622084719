import React, { useState, useEffect, useRef } from 'react';
import { firestore } from '../firebase';
import * as XLSX from 'xlsx'; 
import '../App.css';

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [settings, setSettings] = useState([]);
  const [sortedCustomers, setSortedCustomers] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [searchPhone, setSearchPhone] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchSource, setSearchSource] = useState('');
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [type, setType] = useState('');
  const [source, setSource] = useState('');
  const [priority, setPriority] = useState('');
  const [notes, setNotes] = useState('');
  const [freeServices, setFreeServices] = useState([]);
  const [paidServices, setPaidServices] = useState([]);
  const formRef = useRef(null);

  // Fetch all required data in a single batch and use Firestore listeners to reduce reads
  useEffect(() => {
    const unsubscribeCustomers = firestore.collection('customers').onSnapshot(snapshot => {
      const customersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers(customersData);
      setSortedCustomers(customersData); // Initialize sorted customers
    });

    const unsubscribeServices = firestore.collection('services').onSnapshot(snapshot => {
      const servicesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setServices(servicesData);
    });

    const unsubscribeSettings = firestore.collection('settings').onSnapshot(snapshot => {
      const settingsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSettings(settingsData);
    });

    // Cleanup on unmount
    return () => {
      unsubscribeCustomers();
      unsubscribeServices();
      unsubscribeSettings();
    };
  }, []);

  useEffect(() => {
    handleFilter();
  }, [searchName, searchPhone, searchType, searchSource]);

  const handleSort = (field) => {
    const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    const sorted = [...sortedCustomers].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setSortField(field);
    setSortDirection(direction);
    setSortedCustomers(sorted);
  };

  const handleFilter = () => {
    let filtered = customers;

    if (searchName) {
      filtered = filtered.filter(customer => customer.name.toLowerCase().includes(searchName.toLowerCase()));
    }

    if (searchPhone) {
      filtered = filtered.filter(customer => customer.phone.includes(searchPhone));
    }

    if (searchType) {
      filtered = filtered.filter(customer => customer.type.toLowerCase().includes(searchType.toLowerCase()));
    }

    if (searchSource) {
      filtered = filtered.filter(customer => customer.source.toLowerCase().includes(searchSource.toLowerCase()));
    }

    setSortedCustomers(filtered);
  };

  const handleCheckboxChange = (serviceName, setServices, services) => {
    const newServices = services.includes(serviceName)
      ? services.filter(service => service !== serviceName)
      : [...services, serviceName];
    setServices(newServices);
  };

  const addCustomer = async (e) => {
    e.preventDefault();

    if (!name || !phone) {
      alert("يرجى ملء جميع الحقول المطلوبة");
      return;
    }

    if (phone.length !== 10) {
      alert("رقم الجوال يجب أن يكون مكونًا من 10 أرقام");
      return;
    }

    const existingCustomer = customers.find(
      customer => (selectedCustomer ? customer.id !== selectedCustomer : true) &&
      (customer.name === name || customer.phone === phone)
    );

    if (existingCustomer) {
      alert(`العميل مسجل من قبل باسم ${existingCustomer.name} ورقم جوال ${existingCustomer.phone}`);
      return;
    }

    if (freeServices.length === 0 && paidServices.length === 0) {
      const confirmSave = window.confirm("لم تقم باختيار خدمات للعميل. هل تريد الاستمرار في الحفظ؟");
      if (!confirmSave) return;
    }

    try {
      if (selectedCustomer) {
        await updateCustomer();
      } else {
        const newCustomerId = firestore.collection('customers').doc().id; // توليد ID جديد
        await firestore.collection('customers').doc(newCustomerId).set({
          id: newCustomerId,
          name, phone, type, source, priority, notes, freeServices, paidServices,
          createdAt: new Date() // إضافة تاريخ الإنشاء
        });
      }

      alert("تم الحفظ بنجاح");
      resetForm();
    } catch (error) {
      console.error("Error adding/updating document: ", error);
      alert("حدث خطأ أثناء عملية الحفظ");
    }
  };

  const handleDelete = async (id) => {
    try {
      await firestore.collection('customers').doc(id).delete();
      alert("تم حذف السجل بنجاح");
    } catch (error) {
      console.error("Error deleting document: ", error);
      alert("حدث خطأ أثناء حذف السجل");
    }
  };

  const handleEdit = (customer) => {
    setSelectedCustomer(customer.id);
    setName(customer.name);
    setPhone(customer.phone);
    setType(customer.type);
    setSource(customer.source);
    setPriority(customer.priority);
    setNotes(customer.notes);
    setFreeServices(customer.freeServices || []);
    setPaidServices(customer.paidServices || []);
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const updateCustomer = async () => {
    if (!selectedCustomer) return;

    try {
      const updatedCustomer = {
        name, phone, type, source, priority, notes, freeServices, paidServices
      };
      await firestore.collection('customers').doc(selectedCustomer).update(updatedCustomer);
      alert("تم تعديل السجل بنجاح");
      resetForm();
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("حدث خطأ أثناء تعديل السجل");
    }
  };

  const resetForm = () => {
    setSelectedCustomer(null);
    setName('');
    setPhone('');
    setType('');
    setSource('');
    setPriority('');
    setNotes('');
    setFreeServices([]);
    setPaidServices([]);
  };

  const resetFilters = () => {
    setSearchName('');
    setSearchPhone('');
    setSearchType('');
    setSearchSource('');
  };

  // Export and Import Excel functionality remains unchanged
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(sortedCustomers.map(customer => ({
      الاسم: customer.name,
      الهاتف: customer.phone,
      نوع_العميل: customer.type,
      مصدر_المعرفة: customer.source,
      الأولوية: customer.priority,
      الخدمات_المجانية: customer.freeServices ? customer.freeServices.join(', ') : '',
      الخدمات_المدفوعة: customer.paidServices ? customer.paidServices.join(', ') : ''
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'العملاء');
    XLSX.writeFile(wb, 'customers.xlsx');
  };

  const importFromExcel = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      jsonData.forEach(async (customerData) => {
        const newCustomerId = firestore.collection('customers').doc().id;
        await firestore.collection('customers').doc(newCustomerId).set({
          id: newCustomerId,
          name: customerData['الاسم'] || '',
          phone: customerData['الهاتف'] || '',
          type: customerData['نوع_العميل'] || '',
          source: customerData['مصدر_المعرفة'] || '',
          priority: customerData['الأولوية'] || '',
          freeServices: customerData['الخدمات_المجانية'] ? customerData['الخدمات_المجانية'].split(', ') : [],
          paidServices: customerData['الخدمات_المدفوعة'] ? customerData['الخدمات_المدفوعة'].split(', ') : [],
          createdAt: new Date() // إضافة تاريخ الإنشاء
        });
      });

      alert('تم استيراد البيانات بنجاح');
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="container">
      <h1>العـــــمــــلاء</h1>
      <form ref={formRef} onSubmit={addCustomer}>
        <input 
          type="hidden" 
          value={selectedCustomer || ''} 
        />
        <input 
          type="text" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          placeholder="الاسم" 
          required 
        />
        <input 
          type="text" 
          value={phone} 
          onChange={(e) => setPhone(e.target.value)} 
          placeholder="رقم الجوال" 
          required 
        />
        <select value={type} onChange={(e) => setType(e.target.value)} required>
          <option value="">اختر نوع العميل</option>
          {settings.length > 0 && settings.find(setting => setting.id === 'customerTypes')?.items.map((item, index) => (
            <option key={index} value={item}>{item}</option>
          ))}
        </select>
        <select value={source} onChange={(e) => setSource(e.target.value)} required>
          <option value="">اختر مصدر معرفتنا به</option>
          {settings.length > 0 && settings.find(setting => setting.id === 'sourceTypes')?.items.map((item, index) => (
            <option key={index} value={item}>{item}</option>
          ))}
        </select>
        <select value={priority} onChange={(e) => setPriority(e.target.value)} required>
          <option value="">اختر الأولوية</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
        <textarea 
          value={notes} 
          onChange={(e) => setNotes(e.target.value)} 
          placeholder="ملاحظات" 
        />
        <div className="checkbox-container">
          <h3>الخدمات المجانية</h3>
          {services && services.filter(service => service.type === 'free').map(service => (
            <label key={service.id}>
              <input 
                type="checkbox" 
                value={service.name} 
                checked={freeServices.includes(service.name)}
                onChange={() => handleCheckboxChange(service.name, setFreeServices, freeServices)}
              />
              {service.name}
            </label>
          ))}
        </div>
        <div className="checkbox-container">
          <h3>الخدمات المدفوعة</h3>
          {services && services.filter(service => service.type === 'paid').map(service => (
            <label key={service.id}>
              <input 
                type="checkbox" 
                value={service.name} 
                checked={paidServices.includes(service.name)}
                onChange={() => handleCheckboxChange(service.name, setPaidServices, paidServices)}
              />
              {service.name}
            </label>
          ))}
        </div>
        <button type="submit">{selectedCustomer ? 'تحديث العميل' : 'إضافة العميل'}</button>
      </form>
      <div className="records-count">
        <h2>إجمالي عدد السجلات: {sortedCustomers.length}</h2>
      </div>
      <div className="search-container">
        <div className="search-row">
          <input 
            type="text" 
            value={searchName} 
            onChange={(e) => setSearchName(e.target.value)} 
            placeholder="بحث بالاسم" 
          />
          <input 
            type="text" 
            value={searchPhone} 
            onChange={(e) => setSearchPhone(e.target.value)} 
            placeholder="بحث بالهاتف" 
          />
        </div>
        <div className="search-row">
          <input 
            type="text" 
            value={searchType} 
            onChange={(e) => setSearchType(e.target.value)} 
            placeholder="بحث بنوع العميل" 
          />
          <input 
            type="text" 
            value={searchSource} 
            onChange={(e) => setSearchSource(e.target.value)} 
            placeholder="بحث بمصدر معرفتنا به" 
          />
        </div>
        <button onClick={resetFilters}>إعادة تعيين</button>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('name')}>الاسم</th>
              <th onClick={() => handleSort('phone')}>الهاتف</th>
              <th onClick={() => handleSort('type')}>نوع العميل</th>
              <th onClick={() => handleSort('source')}>مصدر معرفتنا به</th>
              <th onClick={() => handleSort('priority')}>الأولوية</th>
              <th>الخدمات المجانية</th>
              <th>الخدمات المدفوعة</th>
              <th>تعديل</th>
              <th>حذف</th>
            </tr>
          </thead>
          <tbody>
            {sortedCustomers && sortedCustomers.map(customer => (
              <tr key={customer.id}>
                <td>{customer.name}</td>
                <td>{customer.phone}</td>
                <td>{customer.type}</td>
                <td>{customer.source}</td>
                <td>{customer.priority}</td>
                <td>{customer.freeServices ? customer.freeServices.join(', ') : ''}</td>
                <td>{customer.paidServices ? customer.paidServices.join(', ') : ''}</td>
                <td><button onClick={() => handleEdit(customer)} className="small-edit-button">تعديل</button></td>
                <td><button onClick={() => handleDelete(customer.id)} className="small-delete-button">حذف</button></td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={exportToExcel} className="export-button">تصدير إلى Excel</button>
      </div>
      <input type="file" accept=".xlsx, .xls" onChange={importFromExcel} />
    </div>
  );
}

export default Customers;
