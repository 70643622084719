import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { firestore } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import '../App.css';
import { sendWhatsAppNotification } from '../services/twilioService';

// Set the root for the modal
Modal.setAppElement('#root');

function Tasks() {
  const tasksRef = firestore.collection('tasks');
  const settingsRef = firestore.collection('settings');
  const usersRef = firestore.collection('users');
  const projectsRef = firestore.collection('projects');
  const customersRef = firestore.collection('customers');

  const [tasks, setTasks] = useState([]);
  const [settings, setSettings] = useState([]);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [searchName, setSearchName] = useState('');
  const [searchStep, setSearchStep] = useState('');
  const [searchPriority, setSearchPriority] = useState('');
  const [searchAssignedUser, setSearchAssignedUser] = useState('');
  const [searchTaskDate, setSearchTaskDate] = useState('');
  const [searchProject, setSearchProject] = useState('');
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [assignedUser, setAssignedUser] = useState('');
  const [status, setStatus] = useState('');
  const [step, setStep] = useState('');
  const [taskDate, setTaskDate] = useState('');
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [priority, setPriority] = useState('');
  const [notes, setNotes] = useState('');
  const [project, setProject] = useState('');
  const [customer, setCustomer] = useState('');
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionToShow, setDescriptionToShow] = useState('');
  const [updates, setUpdates] = useState([]);
  const [newUpdate, setNewUpdate] = useState('');

  const formRef = useRef(null);

  useEffect(() => {
    const fetchTasks = async () => {
      const snapshot = await tasksRef.orderBy('startDate', 'desc').get();
      const tasksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const cleanedTasks = tasksData.map(task => ({
        ...task,
        updates: task.updates ? task.updates.filter(update => update.date && !isNaN(new Date(update.date))) : []
      }));
      setTasks(cleanedTasks);
      setFilteredTasks(cleanedTasks);
    };

    fetchTasks();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      const snapshot = await settingsRef.get();
      const settingsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSettings(settingsData);
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const snapshot = await usersRef.get();
      const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const snapshot = await projectsRef.get();
      const projectsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(projectsData);
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchCustomers = async () => {
      const snapshot = await customersRef.get();
      const customersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers(customersData);
    };

    fetchCustomers();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [searchName, searchStep, searchPriority, searchAssignedUser, searchTaskDate, searchProject]);

  const handleSort = (field) => {
    const sorted = [...filteredTasks].sort((a, b) => (a[field] > b[field] ? 1 : -1));
    setFilteredTasks(sorted);
  };

  const handleFilter = () => {
    let filtered = tasks;

    if (searchName) {
      filtered = filtered.filter(task => task.name && task.name.toLowerCase().includes(searchName.toLowerCase()));
    }

    if (searchStep) {
      filtered = filtered.filter(task => task.step && task.step.toLowerCase().includes(searchStep.toLowerCase()));
    }

    if (searchPriority) {
      filtered = filtered.filter(task => task.priority && task.priority.toLowerCase().includes(searchPriority.toLowerCase()));
    }

    if (searchAssignedUser) {
      filtered = filtered.filter(task => {
        const assignedUser = users.find(user => user.id === task.assignedUser);
        return assignedUser && assignedUser.name.toLowerCase().includes(searchAssignedUser.toLowerCase());
      });
    }

    if (searchTaskDate) {
      filtered = filtered.filter(task => task.taskDate && task.taskDate.includes(searchTaskDate));
    }

    if (searchProject) {
      filtered = filtered.filter(task => {
        const projectName = projects.find(project => project.id === task.project)?.projectName;
        return projectName && projectName.toLowerCase().includes(searchProject.toLowerCase());
      });
    }

    setFilteredTasks(filtered);
  };

  const handleAddTask = async (e) => {
    e.preventDefault();

    if (!name || !taskDate || !project || !customer) {
      alert("يرجى ملء جميع الحقول المطلوبة");
      return;
    }

    if (!selectedTask) {
      const today = new Date().toISOString().split('T')[0];
      if (taskDate < today) {
        alert("تاريخ انتهاء المهمة يجب أن يكون اليوم أو بعده.");
        return;
      }

      if (taskDate === today) {
        if (!window.confirm("هل أنت متأكد أن تاريخ انتهاء المهمة هو اليوم؟")) {
          return;
        }
      }

      if (description === '') {
        if (!window.confirm("تنبيه! انت لم تضف تفاصيل للمهمة. هل تريد الحفظ؟")) {
          return;
        }
      }
    }

    const updatesArray = selectedTask ? updates : [];

    try {
      let taskId;
      if (selectedTask) {
        taskId = selectedTask;
        await updateTask(updatesArray);
      } else {
        const uniqueId = uuidv4();
        taskId = uniqueId;
        await tasksRef.doc(uniqueId).set({
          id: uniqueId,
          name,
          description,
          assignedUser,
          status,
          step,
          taskDate,
          startDate,
          priority,
          notes,
          project,
          customer,
          updates: updatesArray
        });
      }

      // Fetch the assigned user's details
      const userDoc = await usersRef.doc(assignedUser).get();
      const userData = userDoc.data();

      if (userData && userData.phone) {
        const message = `مهمة جديدة: ${name}\nالوصف: ${description}\nتاريخ الاستحقاق: ${taskDate}`;
        try {
          await sendWhatsAppNotification(userData.phone, message);
          console.log('WhatsApp notification sent successfully');
          alert("تم إرسال إشعار WhatsApp بنجاح");
        } catch (error) {
          console.error('Failed to send WhatsApp notification:', error);
          alert("فشل في إرسال إشعار WhatsApp: " + error.message);
        }
      } else {
        console.warn('User phone number not found. WhatsApp notification not sent.');
      }

      const snapshot = await tasksRef.orderBy('startDate', 'desc').get();
      const tasksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const cleanedTasks = tasksData.map(task => ({
        ...task,
        updates: task.updates ? task.updates.filter(update => update.date && !isNaN(new Date(update.date))) : []
      }));
      setTasks(cleanedTasks);
      setFilteredTasks(cleanedTasks);
      logActivity(selectedTask ? 'update' : 'add', 'Task', { name });
      alert(selectedTask ? "تم تعديل السجل بنجاح" : "تم الحفظ بنجاح");
      setTimeout(() => {
        resetForm();
      }, 2000);
    } catch (error) {
      alert(`حدث خطأ أثناء حفظ المهمة: ${error.message}`);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("هل أنت متأكد من حذف هذه المهمة؟")) {
      const taskToDelete = tasks.find(task => task.id === id);
      await tasksRef.doc(id).delete();
      const snapshot = await tasksRef.get();
      const tasksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const cleanedTasks = tasksData.map(task => ({
        ...task,
        updates: task.updates ? task.updates.filter(update => update.date && !isNaN(new Date(update.date))) : []
      }));
      setTasks(cleanedTasks);
      setFilteredTasks(cleanedTasks);
      logActivity('delete', 'Task', { name: taskToDelete.name });
      alert("تم حذف السجل بنجاح");
    }
  };

  const handleEdit = (task) => {
    setSelectedTask(task.id);
    setName(task.name);
    setDescription(task.description);
    setAssignedUser(task.assignedUser);
    setStatus(task.status);
    setStep(task.step);
    setTaskDate(task.taskDate);
    setStartDate(task.startDate);
    setPriority(task.priority);
    setNotes(task.notes);
    setProject(task.project);
    setCustomer(task.customer);
    setUpdates(task.updates || []);
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const updateTask = async (updatesArray) => {
    if (!selectedTask) return;

    const cleanedUpdates = updatesArray.filter(update => update.date && !isNaN(new Date(update.date)));

    const updatedTask = {
      name,
      description,
      assignedUser,
      status,
      step,
      taskDate,
      startDate,
      priority,
      notes,
      project,
      customer,
      updates: [...cleanedUpdates, { date: new Date().toISOString(), updateDescription: newUpdate }]
    };

    await tasksRef.doc(selectedTask).update(updatedTask);
    const snapshot = await tasksRef.orderBy('startDate', 'desc').get();
    const tasksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const cleanedTasks = tasksData.map(task => ({
      ...task,
      updates: task.updates ? task.updates.filter(update => update.date && !isNaN(new Date(update.date))) : []
    }));
    setTasks(cleanedTasks);
    setFilteredTasks(cleanedTasks);
    logActivity('update', 'Task', { name });
    alert("تم تعديل السجل بنجاح");
    setTimeout(() => {
      resetForm();
    }, 2000);
  };

  const resetForm = () => {
    setSelectedTask(null);
    setName('');
    setDescription('');
    setAssignedUser('');
    setStatus('');
    setStep('');
    setTaskDate('');
    setStartDate(new Date().toISOString().split('T')[0]);
    setPriority('');
    setNotes('');
    setProject('');
    setCustomer('');
    setUpdates([]);
    setNewUpdate('');
  };

  const resetFilters = () => {
    setSearchName('');
    setSearchStep('');
    setSearchPriority('');
    setSearchAssignedUser('');
    setSearchTaskDate('');
    setSearchProject('');
    setFilteredTasks(tasks);
  };

  const logActivity = async (action, entityType, entityData) => {
    const user = localStorage.getItem('username') || 'Unknown User';
    await firestore.collection('log').add({
      action,
      entityType,
      username: user,
      entityData,
      timestamp: new Date(),
    });
  };

  const handleShowMore = (task) => {
    setDescriptionToShow(task.description);
    setUpdates(task.updates ? task.updates.filter(update => update.date && !isNaN(new Date(update.date))) : []);
    setShowDescription(true);
  };

  const handleCloseDescription = () => {
    setShowDescription(false);
    setDescriptionToShow('');
    setUpdates([]);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredTasks.map(task => ({
      اسم_المهمة: task.name,
      وصف_المهمة: task.description,
      المسؤول: users.find(user => user.id === task.assignedUser)?.name || '',
      حالة_المهمة: task.status,
      الخطوة_القادمة: task.step,
      تاريخ_البدء: task.startDate,
      تاريخ_المهمة: task.taskDate,
      الأولوية: task.priority,
      المشروع: projects.find(project => project.id === task.project)?.projectName || '',
      العميل: customers.find(customer => customer.id === task.customer)?.name || ''
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'المهام');
    XLSX.writeFile(wb, 'tasks.xlsx');
  };

  return (
    <div className="container">
      <h1>المهام البيعيه</h1>
      <form ref={formRef} onSubmit={handleAddTask}>
        <input 
          type="text" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          placeholder="اسم المهمة" 
          required 
        />
        <textarea 
          value={description} 
          onChange={(e) => setDescription(e.target.value)} 
          placeholder="وصف المهمة"
        />
        <select value={assignedUser} onChange={(e) => setAssignedUser(e.target.value)} required>
          <option value="">اختر المسؤول</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>{user.name}</option>
          ))}
        </select>
        <select value={status} onChange={(e) => setStatus(e.target.value)} required>
          <option value="">اختر حالة المهمة</option>
          {settings.find(setting => setting.id === 'taskStatuses')?.items.map((item, index) => (
            <option key={index} value={item}>{item}</option>
          ))}
        </select>
        <select value={step} onChange={(e) => setStep(e.target.value)} required>
          <option value="">اختر الخطوة القادمة</option>
          {settings.find(setting => setting.id === 'steps')?.items.map((item, index) => (
            <option key={index} value={item}>{item}</option>
          ))}
        </select>
        <p>تاريخ بدء المهمة</p>
        <input 
          type="date" 
          value={startDate} 
          onChange={(e) => setStartDate(e.target.value)} 
          required 
          onFocus={(e) => e.currentTarget.showPicker()} 
        />
        <p>تحديد موعد انتهاء المهمة</p>
        <input 
          type="date" 
          value={taskDate} 
          onChange={(e) => setTaskDate(e.target.value)} 
          required 
          onFocus={(e) => e.currentTarget.showPicker()} 
        />
        <select value={priority} onChange={(e) => setPriority(e.target.value)} required>
          <option value="">اختر الأولوية</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
        <select value={project} onChange={(e) => setProject(e.target.value)} required>
          <option value="">اختر المشروع</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>{project.projectName}</option>
          ))}
        </select>
        <select value={customer} onChange={(e) => setCustomer(e.target.value)} required>
          <option value="">اختر العميل</option>
          {customers.map((customer) => (
            <option key={customer.id} value={customer.id}>{customer.name}</option>
          ))}
        </select>
        <textarea 
          value={notes} 
          onChange={(e) => setNotes(e.target.value)} 
          placeholder="ملاحظات"
        />
        {selectedTask && (
          <div>
            <h3>التحديثات</h3>
            {updates.map((update, index) => (
              <div key={index}>
                <p>{update.date ? new Date(update.date).toLocaleString() : 'No date'}: {update.updateDescription}</p>
              </div>
            ))}
            <textarea 
              value={newUpdate} 
              onChange={(e) => setNewUpdate(e.target.value)} 
              placeholder="أضف تحديثاً جديداً"
            />
          </div>
        )}
        <button type="submit">{selectedTask ? 'تحديث المهمة' : 'إضافة المهمة'}</button>
      </form>
      <div className="records-count">
        <h2>إجمالي عدد السجلات: {filteredTasks.length}</h2>
      </div>
      <div className="search-container">
        <div className="search-row">
          <input 
            type="text" 
            value={searchName} 
            onChange={(e) => setSearchName(e.target.value)} 
            placeholder="بحث باسم المهمة" 
          />
          <input 
            type="text" 
            value={searchStep} 
            onChange={(e) => setSearchStep(e.target.value)} 
            placeholder="بحث بالخطوة القادمة" 
          />
        </div>
        <div className="search-row">
          <input 
            type="text" 
            value={searchPriority} 
            onChange={(e) => setSearchPriority(e.target.value)} 
            placeholder="بحث بالأولوية" 
          />
          <input 
            type="text" 
            value={searchAssignedUser} 
            onChange={(e) => setSearchAssignedUser(e.target.value)} 
            placeholder="بحث بالمسؤول" 
          />
        </div>
        <div className="search-row">
          <input 
            type="date" 
            value={searchTaskDate} 
            onChange={(e) => setSearchTaskDate(e.target.value)} 
            placeholder="بحث بتاريخ التسليم" 
          />
          <input 
            type="text" 
            value={searchProject} 
            onChange={(e) => setSearchProject(e.target.value)} 
            placeholder="بحث بالمشروع" 
          />
        </div>
        <button onClick={resetFilters}>إعادة تعيين</button>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('name')}>اسم المهمة</th>
              <th onClick={() => handleSort('description')}>وصف المهمة</th>
              <th onClick={() => handleSort('assignedUser')}>المسؤول</th>
              <th onClick={() => handleSort('status')}>حالة المهمة</th>
              <th onClick={() => handleSort('step')}>الخطوة القادمة</th>
              <th onClick={() => handleSort('startDate')}>تاريخ البدء</th>
              <th onClick={() => handleSort('taskDate')}>تاريخ المهمة</th>
              <th onClick={() => handleSort('priority')}>الأولوية</th>
              <th onClick={() => handleSort('project')}>المشروع</th>
              <th onClick={() => handleSort('customer')}>العميل</th>
              <th>تعديل</th>
              <th>حذف</th>
            </tr>
          </thead>
          <tbody>
            {filteredTasks.map(task => (
              <tr key={task.id}>
                <td>{task.name}</td>
                <td>
                  {task.description.length > 50 ? (
                    <>
                      {task.description.substring(0, 50)}...
                      <span
                        onClick={() => handleShowMore(task)}
                        className="show-more-link"
                      >
                        المزيد
                      </span>
                    </>
                  ) : (
                    <>
                      {task.description}
                      <span
                        onClick={() => handleShowMore(task)}
                        className="show-more-link"
                      >
                        المزيد
                      </span>
                    </>
                  )}
                </td>
                <td>{users.find(user => user.id === task.assignedUser)?.name}</td>
                <td>{task.status}</td>
                <td>{task.step}</td>
                <td>{task.startDate}</td>
                <td>{task.taskDate}</td>
                <td>{task.priority}</td>
                <td>{projects.find(project => project.id === task.project)?.projectName}</td>
                <td>{customers.find(customer => customer.id === task.customer)?.name}</td>
                <td>
                  <button className="small-edit-button" onClick={() => handleEdit(task)}>تعديل</button>
                </td>
                <td>
                  <button className="small-delete-button" onClick={() => handleDelete(task.id)}>حذف</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button onClick={exportToExcel} className="export-button">تصدير إلى Excel</button>

      <Modal
        isOpen={showDescription}
        onRequestClose={handleCloseDescription}
        contentLabel="Task Description"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>وصف المهمة</h2>
        <div>
          <p>{descriptionToShow}</p>
          <h3>التحديثات</h3>
          {updates.map((update, index) => (
            <div key={index}>
              <p>{update.date ? new Date(update.date).toLocaleString() : 'No date'}: {update.updateDescription}</p>
            </div>
          ))}
        </div>
        <button onClick={handleCloseDescription}>إغلاق</button>
      </Modal>
    </div>
  );
}

export default Tasks;
