import React, { useState, useEffect, useRef, useCallback } from 'react';
import { firestore } from '../firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import '../App.css';

function Services() {
  const servicesRef = firestore.collection('services');
  const [services, setServices] = useState([]);
  const [sortedServices, setSortedServices] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedService, setSelectedService] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    type: ''
  });

  const formRef = useRef(null);

  // Fetch data once
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesSnapshot = await servicesRef.get();
        const servicesData = servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setServices(servicesData);
        setSortedServices(servicesData);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  // In-memory filtering
  const handleFilter = useCallback(() => {
    if (!search) {
      setSortedServices(services);
    } else {
      setSortedServices(
        services.filter(service =>
          service.name.toLowerCase().includes(search.toLowerCase()) ||
          service.type.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, services]);

  // Filter when search value changes
  useEffect(() => {
    handleFilter();
  }, [search, handleFilter]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddOrUpdateService = async (e) => {
    e.preventDefault();
    const { name, type } = formData;

    if (!name || !type) {
      alert('يرجى ملء جميع الحقول المطلوبة');
      return;
    }

    try {
      if (selectedService) {
        // Update the existing service
        await servicesRef.doc(selectedService).update(formData);
      } else {
        // Add a new service
        const newServiceId = servicesRef.doc().id;
        await servicesRef.doc(newServiceId).set({ ...formData, id: newServiceId });
      }

      // Update state after adding/updating
      const updatedServicesSnapshot = await servicesRef.get();
      const updatedServices = updatedServicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setServices(updatedServices);
      setSortedServices(updatedServices);
      alert(selectedService ? 'تم تعديل السجل بنجاح' : 'تم الحفظ بنجاح');
      resetForm();
    } catch (error) {
      console.error('Error saving service:', error);
      alert('حدث خطأ أثناء عملية الحفظ');
    }
  };

  const handleEdit = (service) => {
    setSelectedService(service.id);
    setFormData({
      name: service.name,
      type: service.type,
    });
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDelete = async (id) => {
    if (window.confirm('هل أنت متأكد من حذف هذه الخدمة؟')) {
      try {
        await servicesRef.doc(id).delete();
        const updatedServices = services.filter(service => service.id !== id);
        setServices(updatedServices);
        setSortedServices(updatedServices);
        alert('تم حذف السجل بنجاح');
      } catch (error) {
        console.error('Error deleting service:', error);
        alert('حدث خطأ أثناء حذف السجل');
      }
    }
  };

  const resetForm = () => {
    setSelectedService(null);
    setFormData({
      name: '',
      type: ''
    });
  };

  const resetFilters = () => {
    setSearch('');
    setSortedServices(services);
  };

  return (
    <div className="container">
      <h1>الخــــدمــــات</h1>
      <form ref={formRef} onSubmit={handleAddOrUpdateService}>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="اسم الخدمة"
          required
        />
        <select name="type" value={formData.type} onChange={handleInputChange} required>
          <option value="">اختر نوع الخدمة</option>
          <option value="free">مجانية</option>
          <option value="paid">مدفوعة</option>
        </select>
        <button type="submit">{selectedService ? 'تحديث الخدمة' : 'إضافة الخدمة'}</button>
      </form>

      <div className="search-container">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="بحث"
        />
        <button onClick={resetFilters}>إعادة تعيين</button>
      </div>

      <div className="records-count">
        <h2>إجمالي عدد السجلات: {sortedServices.length}</h2>
      </div>

      <table>
        <thead>
          <tr>
            <th>اسم الخدمة</th>
            <th>نوع الخدمة</th>
            <th>تعديل</th>
            <th>حذف</th>
          </tr>
        </thead>
        <tbody>
          {sortedServices.map(service => (
            <tr key={service.id}>
              <td>{service.name}</td>
              <td>{service.type === 'free' ? 'مجانية' : 'مدفوعة'}</td>
              <td>
                <button onClick={() => handleEdit(service)} className="small-edit-button">تعديل</button>
              </td>
              <td>
                <button onClick={() => handleDelete(service.id)} className="small-delete-button">حذف</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Services;
