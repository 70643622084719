import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';

const Login = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const usersRef = firestore.collection('users');
      const querySnapshot = await usersRef.where('email', '==', email).where('password', '==', password).get();

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        if (rememberMe) {
          localStorage.setItem('email', email);
          localStorage.setItem('username', userData.name);
        } else {
          localStorage.removeItem('email');
          localStorage.removeItem('username');
        }
        onLoginSuccess(userData.name);
        setError('');
      } else {
        setError('البريد الإلكتروني أو كلمة المرور غير صحيحة');
      }
    } catch (error) {
      setError('حدث خطأ أثناء تسجيل الدخول. حاول مرة أخرى.');
    }
  };

  return (
    <div className="login-container">
      <h2>تسجيل الدخول</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleLogin}>
        <label>
          البريد الإلكتروني:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <label>
          كلمة المرور:
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
           
          </div>
        </label>
        <span
          className="show-password-text"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? 'إخفاء كلمة المرور' : 'إظهار كلمة المرور'}
        </span>
        <div className="remember-me">
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label>تذكرني</label>
        </div>
       
        <button type="submit">تسجيل الدخول</button>
      </form>
    </div>
  );
};

export default Login;
