import React, { useState, useEffect, useCallback } from 'react';
import { firestore } from '../firebase';
import '../App.css';

function Settings() {
  const [steps, setSteps] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [sourceTypes, setSourceTypes] = useState([]);

  const [newStep, setNewStep] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [newCustomerType, setNewCustomerType] = useState('');
  const [newSourceType, setNewSourceType] = useState('');

  // Fetch all settings using onSnapshot for real-time updates
  useEffect(() => {
    const unsubscribe = firestore.collection('settings').onSnapshot((snapshot) => {
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        switch (doc.id) {
          case 'steps':
            setSteps(data.items || []);
            break;
          case 'taskStatuses':
            setTaskStatuses(data.items || []);
            break;
          case 'customerTypes':
            setCustomerTypes(data.items || []);
            break;
          case 'sourceTypes':
            setSourceTypes(data.items || []);
            break;
          default:
            break;
        }
      });
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  // Memoized handler for step addition
  const handleAddStep = useCallback(async () => {
    if (newStep) {
      const updatedSteps = [...steps, newStep];
      await firestore.collection('settings').doc('steps').set({ items: updatedSteps });
      setNewStep('');
    }
  }, [newStep, steps]);

  const handleAddStatus = useCallback(async () => {
    if (newStatus) {
      const updatedStatuses = [...taskStatuses, newStatus];
      await firestore.collection('settings').doc('taskStatuses').set({ items: updatedStatuses });
      setNewStatus('');
    }
  }, [newStatus, taskStatuses]);

  const handleAddCustomerType = useCallback(async () => {
    if (newCustomerType) {
      const updatedCustomerTypes = [...customerTypes, newCustomerType];
      await firestore.collection('settings').doc('customerTypes').set({ items: updatedCustomerTypes });
      setNewCustomerType('');
    }
  }, [newCustomerType, customerTypes]);

  const handleAddSourceType = useCallback(async () => {
    if (newSourceType) {
      const updatedSourceTypes = [...sourceTypes, newSourceType];
      await firestore.collection('settings').doc('sourceTypes').set({ items: updatedSourceTypes });
      setNewSourceType('');
    }
  }, [newSourceType, sourceTypes]);

  const handleDeleteItem = useCallback(async (collection, index) => {
    let updatedItems;
    switch (collection) {
      case 'steps':
        updatedItems = steps.filter((_, i) => i !== index);
        await firestore.collection('settings').doc('steps').set({ items: updatedItems });
        setSteps(updatedItems);
        break;
      case 'taskStatuses':
        updatedItems = taskStatuses.filter((_, i) => i !== index);
        await firestore.collection('settings').doc('taskStatuses').set({ items: updatedItems });
        setTaskStatuses(updatedItems);
        break;
      case 'customerTypes':
        updatedItems = customerTypes.filter((_, i) => i !== index);
        await firestore.collection('settings').doc('customerTypes').set({ items: updatedItems });
        setCustomerTypes(updatedItems);
        break;
      case 'sourceTypes':
        updatedItems = sourceTypes.filter((_, i) => i !== index);
        await firestore.collection('settings').doc('sourceTypes').set({ items: updatedItems });
        setSourceTypes(updatedItems);
        break;
      default:
        break;
    }
  }, [steps, taskStatuses, customerTypes, sourceTypes]);

  return (
    <div className="Settings container">
      <h1>الإعدادات</h1>
      
      {/* Step Management */}
      <div className="settings-section">
        <form onSubmit={(e) => { e.preventDefault(); handleAddStep(); }} className="settings-form">
          <h2>إدارة الخطوة القادمة</h2>
          <input 
            type="text" 
            value={newStep} 
            onChange={(e) => setNewStep(e.target.value)} 
            placeholder="أضف خطوة جديدة" 
          />
          <button type="submit" className="add-button">إضافة</button>
        </form>
        <table className="settings-table">
          <tbody>
            {steps.map((step, index) => (
              <tr key={index}>
                <td className="settings-item">{step}</td>
                <td className="settings-action">
                  <button className="small-delete-button" onClick={() => handleDeleteItem('steps', index)}>حذف</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Task Status Management */}
      <div className="settings-section">
        <form onSubmit={(e) => { e.preventDefault(); handleAddStatus(); }} className="settings-form">
          <h2>إدارة حالة المهمة</h2>
          <input 
            type="text" 
            value={newStatus} 
            onChange={(e) => setNewStatus(e.target.value)} 
            placeholder="أضف حالة جديدة" 
          />
          <button type="submit" className="add-button">إضافة</button>
        </form>
        <table className="settings-table">
          <tbody>
            {taskStatuses.map((status, index) => (
              <tr key={index}>
                <td className="settings-item">{status}</td>
                <td className="settings-action">
                  <button className="small-delete-button" onClick={() => handleDeleteItem('taskStatuses', index)}>حذف</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Customer Type Management */}
      <div className="settings-section">
        <form onSubmit={(e) => { e.preventDefault(); handleAddCustomerType(); }} className="settings-form">
          <h2>إدارة نوع العميل</h2>
          <input 
            type="text" 
            value={newCustomerType} 
            onChange={(e) => setNewCustomerType(e.target.value)} 
            placeholder="أضف نوع عميل جديد" 
          />
          <button type="submit" className="add-button">إضافة</button>
        </form>
        <table className="settings-table">
          <tbody>
            {customerTypes.map((type, index) => (
              <tr key={index}>
                <td className="settings-item">{type}</td>
                <td className="settings-action">
                  <button className="small-delete-button" onClick={() => handleDeleteItem('customerTypes', index)}>حذف</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Source Type Management */}
      <div className="settings-section">
        <form onSubmit={(e) => { e.preventDefault(); handleAddSourceType(); }} className="settings-form">
          <h2>إدارة مصدر معرفتنا به</h2>
          <input 
            type="text" 
            value={newSourceType} 
            onChange={(e) => setNewSourceType(e.target.value)} 
            placeholder="أضف مصدر جديد" 
          />
          <button type="submit" className="add-button">إضافة</button>
        </form>
        <table className="settings-table">
          <tbody>
            {sourceTypes.map((source, index) => (
              <tr key={index}>
                <td className="settings-item">{source}</td>
                <td className="settings-action">
                  <button className="small-delete-button" onClick={() => handleDeleteItem('sourceTypes', index)}>حذف</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Settings;
